import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { localStorageRemember, localStorageSet } from '../../services/localStorage'
import { fetchNotifications } from './notifications'

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        currentUser: null,
        droits: {},
        userFetched: false,
        canShowCdba: false,
        vrpAsCustomer: localStorageRemember('vrpAsCustomer', null),
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.userFetched = true
            state.currentUser = action.payload
            state.droits = action.payload.droits?.reduce((carry, droit) => ({ ...carry, [droit.slug]: true }), {}) ?? {}
        },
        setDemat: (state, action) => {
            state.currentUser.bpcustomer.demat = action.payload
        },
        setUserFetched: state => {
            state.userFetched = true
        },
        setVrpAsCustomer: (state, action) => {
            localStorageSet('vrpAsCustomer', action.payload)
            state.vrpAsCustomer = action.payload
        },
        setCanShowCdba: (state, action) => {
            state.canShowCdba = action.payload
        },
    },
})

export const { setCurrentUser, setUserFetched, setNeedChangePassword, setVrpAsCustomer, setDemat, setCanShowCdba } =
    customerSlice.actions

export const setCurrentCustomer = customer => dispatch => {
    dispatch(setCurrentUser(customer))
    dispatch(fetchNotifications())

    if (customer.droits.find(droit => droit.slug === 'cdba') !== undefined) {
        axios
            .get('/api/cdba/search?size=1')
            .then(response => {
                dispatch(setCanShowCdba(!!response.data.total))
            })
            .catch(() => {})
    }
}

export const fetchCurrentUser = () => dispatch => {
    return axios
        .get('/api/customer')
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentCustomer(response.data.data))
        })
        .catch(() => dispatch(setVrpAsCustomer(null)))
        .finally(() => dispatch(setUserFetched()))
}

export const fetchVrpAsCustomer = id => dispatch => {
    if (!id) {
        return new Promise(resolve => {
            dispatch(setVrpAsCustomer(null))
            resolve()
        })
    }

    return axios
        .get(`/api/vrp/customers?id=${id}`)
        .then(res => dispatch(setVrpAsCustomer(res.data.data)))
        .finally(() => dispatch(setUserFetched()))
}

export const resetMyVrpAsCustomer = value => dispatch => {
    dispatch(setVrpAsCustomer(value))
}

export const logCustomer = (username, password, bpcnum) => dispatch => {
    return axios
        .post('/api/customer/login', { username, password, bpcnum })
        .then(response => {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.token
            dispatch(setCurrentCustomer(response.data.data))
            return response
        })
        .finally(() => dispatch(setUserFetched()))
}

export const logOutCustomer = () => {
    return axios.get('/api/customer/logout').then(() => window.location.reload())
}

export default customerSlice.reducer
