import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { alertError } from './alerts'

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: null,
    },
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        removeNotifications: (state, action) => {
            state.notifications = state.notifications.filter(notification => !action.payload.includes(notification.id))
        },
    },
})

export const { setNotifications, removeNotifications } = notificationsSlice.actions

const warrantiesCase = notifications =>
    notifications?.map(notification => ({
        ...notification,
        type:
            notification.type === 'serrequest' && notification.detail?.type === 'garantie'
                ? 'warranties'
                : notification.type,
    }))

export const fetchNotifications = () => dispatch => {
    return axios
        .get('/api/notifications')
        .then(response => dispatch(setNotifications(warrantiesCase(response.data.data))))
}

export const notificationSeen = (ids, error) => dispatch => {
    dispatch(removeNotifications(ids))

    axios
        .post('/api/notifications/bulk-update', { seen: true }, { params: { items: ids } })
        .then(() => {
            dispatch(fetchNotifications())
        })
        .catch(() => alertError(error))
}

export default notificationsSlice.reducer
